/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Image from "../../assets/blackbg.jpg";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { fadeIn } from "../../variant";

/*
-social medias
-contact button is hidden
-portfolio link hidden
-size added to icon
 */
const allowedContacts =
  "https://www.linkedin.com/in/dylan-mccallum/overlay/contact-info/";

function Banner() {
  const onContactClicked = () => {
    if (
      allowedContacts ===
      "https://www.linkedin.com/in/dylan-mccallum/overlay/contact-info/"
    ) {
      return (window.location.href = allowedContacts);
    } else {
      return alert("Something Happened");
    }
  };
  return (
    <React.Fragment>
      <section
        className="min-h-[85vh] lg:min-h-[78vh] flex items-center"
        id="home"
      >
        <div className="container mx-auto">
          <div className="flex flex-col gap-y-8 lg:flex-row items-center lg:gap-x-12">
            {/*text */}
            <div className="flex-1 text-center font-secondary lg:text-left">
              <motion.h1
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
              >
                Dylan <span>McCallum</span>
              </motion.h1>
              <motion.div
                variants={fadeIn("up", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]"
              >
                <span className="mr-2 text-white">I am</span>
                <TypeAnimation
                  sequence={[
                    "A Full-Stack Software Developer",
                    2000,
                    "A Computer Programmer",
                    2000,
                    "A Navy Veteran",
                    2000,
                  ]}
                  speed={50}
                  className="text-accent"
                  wrapper="span"
                  repeat={Infinity}
                />
              </motion.div>
              <motion.p
                variants={fadeIn("up", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="mb-8 max-w-lg mx-auto lg:mx-0"
              >
                <strong>
                  I'm a Software Engineer with extensive experience in web
                  design, coding, and API development.
                </strong>
              </motion.p>
              <motion.div
                variants={fadeIn("up", 0.6)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
              >
                <button
                  type="button"
                  className="btn btn-lg"
                  onClick={onContactClicked}
                >
                  Contact Me
                </button>
              </motion.div>
              {/*socials */}
              <motion.div
                variants={fadeIn("up", 0.7)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0"
              >
                <a href="https://github.com/SAR-code">
                  <FaGithub className="w-[45px] h-[45px]" />
                </a>
                <a href="https://www.linkedin.com/in/dylan-mccallum">
                  <FaLinkedin className="w-[45px] h-[45px]" />
                </a>
              </motion.div>
            </div>
            {/*pictures*/}
            <motion.div
              variants={fadeIn("down", 0.5)}
              initial="hidden"
              whileInView={"show"}
              className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]"
            >
              <img
                className="border-8 border-accent rounded-e-full"
                src={Image}
                alt=""
              />
            </motion.div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Banner;
