import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fadeIn } from "../../variant";
import Image from "../../assets/laptop.jpg";

/*
-portfolio link hidden
-project stats hidden
-count hidden
-button hidden
-check mb on section
-threshold 0 keeps nums in longer

*/

function About() {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <React.Fragment>
      <section className="section" id="about" ref={ref}>
        <div className="container mx-auto mb-32">
          <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen">
            {/*image*/}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex-1 bg-contain bg-no-repeat h-[640px] bg-top mt-32"
            >
              <img
                className="border-2 border-accent rounded-lg"
                src={Image}
                alt=""
              />
            </motion.div>
            {/*text*/}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex-1"
            >
              <h2 className="h2 text-accent">About me.</h2>
              <h3 className="h3 mb-4">
                I'm a Full-Stack Software Developer and Veteran.{" "}
              </h3>
              <p className="mb-5 glass-effect">
                My experience includes building scalable web applications using
                React.js, .NET, C#, and SQL. I am driven to learn new skills and
                to be able to apply them to have a deeper understanding. While
                serving in the Navy as an Aviation Rescue Swimmer, I have
                developed essential skills such as leadership, teamwork, and
                communication to work effectively with others while producing an
                exceptional product in a timely manner.
              </p>
              <div className="flex gap-x-6 lg:gap-x-10 mb-12">
                <div>
                  <div className="text-[32px] font-tertiary text-gradient mb-2 mt-5">
                    {inView ? (
                      <CountUp start={0} end={11} duration={4} />
                    ) : null}
                  </div>
                  <div className="font-primary text-sm text-accent tracking-[2px]">
                    <strong>
                      Years <br />
                      Served
                    </strong>
                  </div>
                </div>
                <div>
                  <div className="text-[32px] font-tertiary text-gradient mb-2 mt-5">
                    {inView ? <CountUp start={0} end={2} duration={4} /> : null}
                  </div>
                  <div className="font-primary text-accent text-sm tracking-[2px]">
                    <strong>
                      Years of
                      <br />
                      Experience
                    </strong>
                  </div>
                </div>
                <div className="hidden">
                  <div className="text-[40px] font-tertiary text-gradient mb-2">
                    {inView ? (
                      <CountUp start={0} end={13} duration={4} />
                    ) : null}
                  </div>
                  <div className="font-primary text-accent text-sm tracking-[2px]">
                    <strong>
                      Projects <br />
                      Completed
                    </strong>
                  </div>
                </div>
                <div className="flex gap-x-8 items-center">
                  <button className="btn btn-lg hidden">Contact Me</button>
                  <a
                    href="https://github.com/SAR-code"
                    className="text-gradient btn-link hidden"
                  >
                    My Portfolio
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default About;
