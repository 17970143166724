import React from "react";
import About from "./components/about/About";
import Header from "./components/header/Header";
import Banner from "./components/banner/Banner";
import Nav from "./components/navigation/Nav";
//import Service from "./components/service/Service";
import Work from "./components/work/Work";

//import Contact from "./components/contact/Contact";

function App() {
  return (
    <div className="bg-site bg-no-repeat bg-cover overflow-hidden">
      <Header />
      <Banner />
      <Nav />
      <About />
      {/* <Service /> */}
      <Work />
      {/* <Contact /> */}
      {/* <div className="h-[4000px]"></div> */}
    </div>
  );
}

export default App;
