import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../../variant";

/*
-Revisit href
-adjust sizing
*/

import logo1 from "../../assets/Makai2.png";
import logo2 from "../../assets/MoneFiLogoDefault.png";
import logo3 from "../../assets/game.jpg";

const allowedOrigins = "https://github.com/SAR-code";

function Work() {
  const onProjectClick = () => {
    console.log("Project Clicked");

    if (allowedOrigins === "https://github.com/SAR-code") {
      return (window.location.href = allowedOrigins);
    } else {
      return alert("Something Happened");
    }
  };
  return (
    <React.Fragment>
      <section className="section" id="work">
        <div className="container mx-auto mt-48 lg:mt-0">
          <div className="flex flex-col lg:flex-row gap-x-10">
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
            >
              {/*text*/}
              <div>
                <h2 className="h2 leading-tight text-accent">
                  Most Recent <br />
                  Projects.
                </h2>
                <p className="max-w-sm mb-16 glass-effect">
                  My newest projects are on display on my GitHub page and on
                  this website. I'm expanding my skill set and keeping up with
                  the latest technologies!
                </p>
                <button
                  onClick={onProjectClick}
                  className="btn btn-sm"
                  type="button"
                >
                  View my projects
                </button>
              </div>
              {/*image*/}
              <div className="group relative overflow-hidden border-2 border-accent rounded-xl">
                {/*overlay*/}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/*img*/}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={logo3}
                  alt=""
                />
                {/*pretitle*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Web Game Using C#</span>
                </div>
                {/*title*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Tic-Tac-Toe</span>
                </div>
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex-1 flex flex-col gap-y-10"
            >
              {/*image2*/}
              <div className="group relative overflow-hidden border-2 border-accent rounded-xl">
                {/*overlay*/}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/*img*/}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={logo1}
                  alt=""
                />
                {/*pretitle*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Full-Stack Development</span>
                </div>
                {/*title*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Makai Rentals</span>
                </div>
              </div>
              {/*image3*/}
              <div className="group relative overflow-hidden border-2 border-accent rounded-xl">
                {/*overlay*/}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/*img*/}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={logo2}
                  alt=""
                />
                {/*pretitle*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Full-Stack Development</span>
                </div>
                {/*title*/}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">MoneFi</span>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Work;
