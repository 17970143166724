import React from "react";
import "./header.css";

/*
-button hidden
-clickhandlers
*/

function Header() {
  return (
    <React.Fragment>
      <header className="py-8" id="header">
        <div className="container mx-auto">
          <div className="main-logo flex justify-between items-center">
            <strong>
              <span className="text-5xl text-accent">Dylan's Briefcase</span>
            </strong>
            <button className="btn btn-sm hidden">Work with me</button>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
